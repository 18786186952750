@use '@amzn/awsui-design-tokens/polaris.scss' as awsui
// @import '@amzn/awsui-global-styles/dist'
html
    font-family: 'Amazon Ember', sans-serif
    // color: white
    // background: rgb(35, 35, 35)
    //background: awsui.$color-background-container-content
    // background: $
    text-align: center
// .Moniker
//     font-family: 'Amazon Ember', sans-serif

h1
    font-weight: 100
    text-align: center

//.Moniker
//    outline: 1px solid blue
//    height: 100%
//.routeList
//    //display: relative
//    outline: 1px solid red
//    height: 100%