.regionStatusString {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    display: inline;
    &:not(:last-child) {
      &:after {
        content: '\00a0| ';
        color: #aaaaaa;
      }
    }
  }
}

.nonBulletedList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.selectFilter {
  max-width: 200px;
  flex-grow: 3;
  width: auto;
}

.inputFilter {
  order: 0;
  flex-grow: 7;
  width: auto;
  max-width: 650px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.inputContainer {
  display: flex;
  flex-wrap: wrap;
  order: 0;
  flex-grow: 10;
  margin-right: 2rem;
}
