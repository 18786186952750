.Form
    .columns
        width: 600px
        > div
            width: calc(50% - 8px)
            div:has(+div):has(>input)
                // opacity: .5
                &:not(:hover)
                    // text-decoration: line-through
                + div
                    input
                        background: #cfc !important
.bad
    background: pink !important
