body
    // width: 100vw
    // overflow-x: hidden
.Route
    //position: absolute
    // top: 0
    // float: center
    //left: 0
    //right: 0
    //transition: .2s
    text-align: center
    padding: 20px
    &:not(.visible)
        display: none
        pointer-events: none
        opacity: 0
        &.center
            transform: scale(.5)
        &.top
            transform: translateY(-100vw)
        &.bottom
            transform: translateY(100vw)
        &.left
            transform: translateX(-100vw)
        &.right
            transform: translateX(100vw)
    > div
        display: inline-block
        padding: 10px
        border-radius: 5px
        text-align: left
        // background-color: transparentize(black, .5)
