.LocSubscribersField
    div:has(>div>div>div>div>div>input)
        // background: red
        input
            width: 250px !important
    .addRow
        text-align: right
        padding: 5px
        button
            display: inline-block
